import React, { useState, useEffect } from "react";
import useStore from "../zustand/store";
import { Connection, PublicKey } from "@solana/web3.js";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { useBalance } from "wagmi";
import convertNative from "../utils/convertNative";
import { useAccount } from "wagmi";
import getSolBalancesSpl from "../utils/getSolBalanceSpl";
function useGetBalance(coinAddress, enableEvm) {
  const [nonNativeBalance, setNonNativeBalance] = useState(0);
  const { primaryWallet, setPrimaryWallet } = useDynamicContext();
  const [isBalanceLoading, setIsBalanceLoading] = useState(false);
  const fromChain = useStore((state) => state.fromChain);
  const { address, isConnected } = useAccount();
  const fromCoin = useStore((state) => state.fromCoin);
  const { data, isLoading } = useBalance({
    address: address,
    token: convertNative(fromCoin?.address || ""),
    chainId: Number(fromChain?.chainId || 0),
    enabled:
      (fromChain.networkType == "evm" || enableEvm) && isConnected
        ? true
        : false,
  });
  async function getNativeBal() {
    if (!primaryWallet) return;

    const connection = await primaryWallet.connector.getPublicClient();

    if (!connection) return;

    try {
      const publicKey = new PublicKey(primaryWallet.address);
      setIsBalanceLoading(true);
      const balanceSol = await connection.getBalance(publicKey);

      // console.log("Balance in lamports:", balance);
      const balanceSolVal = balanceSol / 1000000000; // Convert lamports to SOL
      setNonNativeBalance(balanceSolVal);
      setIsBalanceLoading(false);
    } catch (e) {
      setIsBalanceLoading(false);
    }
  }
  async function getSpl(address) {
    try {
      setIsBalanceLoading(true);
      let res = await getSolBalancesSpl(primaryWallet.address);
      console.log(res, "splbalance");
      setIsBalanceLoading(false);
      setNonNativeBalance(res?.[fromCoin?.address]);
    } catch (err) {
      console.log(err);
      setIsBalanceLoading(false);
    }
  }
  let eventId;

  async function setEvent() {
    if (!primaryWallet) return;
    const connection = await primaryWallet.connector.getPublicClient();
    console.log(connection, "connection");
    if (!connection) return;
    const publicKey = new PublicKey(primaryWallet.address);
    if (!publicKey) return;
    eventId = connection.onAccountChange(publicKey, (e) => {
      console.log("onAccountChange123", fromCoin?.symbol?.toLowerCase());
      if (fromChain.networkType == "sol" && primaryWallet?.address) {
        if (fromCoin?.symbol?.toLowerCase() === "sol") {
          getNativeBal();
        } else {
          console.log("called spl");
          getSpl();
        }
      }
    });
  }
  useEffect(() => {
    fromChain?.networkType === "sol" && setEvent();
    return async () => {
      if (!primaryWallet) return;
      const connection = await primaryWallet.connector.getPublicClient();
      connection.removeAccountChangeListener(eventId);
    };
  }, [primaryWallet?.address, fromChain?.networkType, fromCoin]);
  useEffect(() => {
    if (fromChain.networkType == "sol" && primaryWallet?.address) {
      if (fromCoin?.symbol?.toLowerCase() === "sol") {
        getNativeBal();
      } else {
        getSpl();
      }
    } else if (fromChain.networkType == "evm" && isConnected) {
      setNonNativeBalance(Number(data?.formatted || 0));
    } else {
    }
  }, [
    fromChain.networkType,
    fromCoin?.address,
    data,
    primaryWallet?.address,
    address,
  ]);
  return {
    getbal: nonNativeBalance,
    isBalanceLoading:
      fromChain?.networkType == "sol"
        ? isBalanceLoading
        : fromChain?.networkType == "evm"
        ? isLoading
        : false,
  };
}

export default useGetBalance;
