import { useState, useEffect } from "react";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import truncate from "../../utils/truncate";
import ethertousd from "../../utils/ethertousd";
import useStore from "../../zustand/store";
import styles from "./WidgetForm.module.css";
import { useAccount, useNetwork, useBalance, useSwitchNetwork } from "wagmi";
import convertNative from "../../utils/convertNative";
import useWalletDetails from "../../hooks/useWalletDetails";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import useSolBalance from "../../hooks/useSolBalance";
import useTronBalance from "../../hooks/useTronBalance";
import { CircularProgress } from "@mui/material";
import useRegisterError from "../../hooks/useRegisterError";
import validError from "../../utils/validError";
export default function TxnButton({
  routesData,
  routes,
  fetchBalancePrev,
  amount,
  isAmountTyped,
  handleShowWallet,
  setConnectWalletField,
  handleConfirmClose,
  fetchBalance,
  balanceSol,
  getCoinBalance,
}) {
  const gradient = useStore((state) => state.gradient);
  const configuration = useStore((state) => state.configuration);
  const fromChain = useStore((state) => state.fromChain);
  const fromCoin = useStore((state) => state.fromCoin);
  const toChain = useStore((state) => state.toChain);
  const toCoin = useStore((state) => state.toCoin);
  const { isConnected, address } = useAccount();
  const { chain } = useNetwork();
  const { getBothWalletConnection, getConnectWallet } = useWalletDetails({});
  const { connected, address: tronAdrress, disconnect } = useWallet();
  const toDisconnect = useStore((state) => state.toDisconnect);
  const customWallet = useStore((state) => state.customWallet);
  const { getWallet } = useWalletDetails({});
  const connectWalletName = getConnectWallet(fromChain, toChain);

  const { tronBal } = useTronBalance(fromChain);
  const isChainsLoaded = fromChain.chain?.length && toChain.chain?.length;
  const isCoinsLoaded = fromCoin.address?.length && toCoin.address?.length;
  const { registerError } = useRegisterError();

  const { switchNetwork } = useSwitchNetwork({
    chainId: Number(fromChain?.chainId || "0"),
    throwForSwitchChainNotSupported: true,
    onError(error) {
      let isValidError = validError(error.message);
      if (isValidError)
        registerError({ module: "wallet-error-switch", error: error.message });
    },
  });
  const balances = useBalance({
    address: address,
    token: convertNative(fromCoin?.address || ""),
    chainId: Number(fromChain?.chainId || 0),
    enabled: fromChain?.networkType == "evm" ? true : false,
  });

  const selectedCoinbalance = truncate(getCoinBalance || 0, 6);
  // truncate(
  //   (fetchBalancePrev?.data?.data?.[fromChain?.networkType]?.[
  //     fromChain.chainId
  //   ]?.[fromCoin.address]?.balance || 0) /
  //     (Math.pow(
  //       10,
  //       fetchBalancePrev.data?.data?.[fromChain?.networkType]?.[
  //         fromChain.chainId
  //       ]?.[fromCoin.address]?.asset?.decimals || 0
  //     ) || 0),
  //   6
  // );

  // console.log(balances.data, "balances");
  const [ctaInfoText, setCtaInfoText] = useState({
    text: "",
    isGradient: false,
    color: "bw-bg-border-primary",
  });
  async function handleSubmit() {
    if (
      fromChain?.networkType === "evm" &&
      Number(fromChain?.chainId || "0") !== chain?.id
    ) {
      try {
        switchNetwork();
      } catch (err) {
        console.log(err, "err");
      }
    } else {
      handleConfirmClose();
    }
  }
  console.log(balanceSol, "balanceSol");
  const { primaryWallet, isFullyConnected } = useDynamicContext();
  const isBothWalletConnected =
    fromChain?.networkType === toChain.networkType
      ? Boolean(getWallet(fromChain)?.address?.length || 0)
      : getBothWalletConnection(
          fromChain?.networkType,
          customWallet?.length > 30 ? "customWallet" : toChain?.networkType
        );

  function getGas(amountInEther, decimals) {
    let parsedVal = amountInEther / Math.pow(10, decimals);
    if (parsedVal < 0.000001) {
      console.log(
        parsedVal.toFixed(20),
        parsedVal,
        amountInEther,
        "parsedval123"
      );
      return parsedVal.toFixed(20);
    } else {
      console.log(
        parsedVal.toFixed(20),
        parsedVal,
        amountInEther,
        "parsedval1345"
      );
      return parsedVal;
    }
  }
  function getNativeBal(token) {
    return (
      truncate(
        fetchBalance.data?.data?.[token?.networkType]?.[
          token.chainId?.toString()
        ]?.[token?.address]?.balance /
          Math.pow(
            10,
            fetchBalance.data?.data?.[token?.networkType]?.[
              token.chainId?.toString()
            ]?.[token?.address]?.asset?.decimals || 0
          ),
        6
      ) || 0
    );
  }

  function handleError() {
    let gasItem = {};
    function setGasObj(token, item) {
      let keyArray = Object.keys(gasItem);
      if (gasItem[token?.chainId]) {
        gasItem[token?.chainId].amountInToken += item.amountInToken;
        gasItem[token?.chainId].gas += getGas(
          item.amountInToken,
          token?.decimals
        );
      } else {
        gasItem[token?.chainId] = {
          amountInToken: item.amountInToken,
          gas: getGas(item.amountInToken, token?.decimals),
          token,
          nativeBal:
            customWallet?.length > 30 &&
            !toDisconnect &&
            token?.chainId == toChain?.chainId &&
            toChain?.chainId !== fromChain?.chainId
              ? 0
              : token?.networkType == "sol"
              ? balanceSol
              : token?.networkType == "tron"
              ? tronBal
              : getNativeBal(token),
          isFromChain: fromChain?.chainId === token?.chainId.toString(),
          order:
            fromChain?.chainId === token?.chainId
              ? 1
              : toChain?.chainId === token?.chainId
              ? 2
              : keyArray.length > 2
              ? 3
              : 2,
        };
      }
    }
    routesData?.fee?.forEach((item) => {
      const { token } = item;
      if (item.source.toLowerCase() == "from_source_wallet") {
        setGasObj(token, item);
      }
    });
    let coinBalance = selectedCoinbalance;
    console.log(coinBalance, gasItem, balanceSol, "coinBalance");
    if (amount > coinBalance) {
      setCtaInfoText({
        text: `Insufficient Balance ${fromCoin.coin || ""}`,
        isGradient: false,
        color: "bw-bg-border-primary",
      });
    } else if (
      ((fetchBalance?.isLoading || fetchBalance.isFetching) &&
        fromChain?.networkType === "evm") ||
      ((typeof balanceSol == "undefined" || balanceSol === null) &&
        fromChain?.networkType === "sol") ||
      (!tronBal && fromChain?.networkType === "tron")
    ) {
      setCtaInfoText({
        text: `Fetching Balance..`,
        isGradient: false,
        color: "bw-bg-border-primary",
      });
    } else {
      let sortGas = Object.values(gasItem).sort((a, b) => a.order - b.order);
      // console.log(sortGas, "gasItem");
      sortGas.some((val) => {
        if (
          (val?.isFromChain &&
            amount <= coinBalance &&
            Number(val.nativeBal) - amount < val.gas &&
            fromCoin?.isNative) ||
          (amount <= coinBalance &&
            Number(val.nativeBal) < val.gas &&
            !fromCoin?.isNative &&
            val?.isFromChain)
        ) {
          setCtaInfoText({
            text: `Insufficient ${val?.token?.symbol || ""} for gas on ${
              val.token.blockchain
            }`,
            isGradient: false,
            color: "bw-bg-border-primary",
          });
          return true;
        } else {
          if (
            val.nativeBal < val.gas &&
            !(
              customWallet?.length > 30 &&
              val?.token?.chainId == toChain?.chainId
            )
          ) {
            setCtaInfoText({
              text: `Insufficient ${val?.token?.symbol || ""} for gas on ${
                val.token.blockchain
              }`,
              isGradient: false,
              color: "bw-bg-border-primary",
            });
            return true;
          } else
            setCtaInfoText({
              text: "",
              isGradient: true,
              color: gradient ? gradient : styles.gradientbutton,
            });
        }
      });
    }
  }
  // console.log(routes?.data?.data?.quotes?.length, "data123");
  useEffect(() => {
    if (!fromCoin.coin.length || !toCoin?.coin.length) {
      setCtaInfoText({
        text: "Select Token",
        isGradient: true,
        color: gradient ? gradient : styles.gradientbutton,
      });
    } else if (!isBothWalletConnected) {
      setCtaInfoText({
        text: ``,
        isGradient: true,
        color: gradient ? gradient : styles.gradientbutton,
      });
    } else if (typeof amount !== "undefined" && Number(amount || 0) <= 0) {
      setCtaInfoText({
        text: "Enter Amount",
        isGradient: false,
        color: "bw-bg-border-primary",
      });
    } else if (routes.isFetching || !isAmountTyped) {
      setCtaInfoText({
        text: "Loading Routes...",
        isGradient: false,
        color: "bw-bg-border-primary",
      });
    } else if (
      (fromCoin?.address == toCoin?.address &&
        fromChain?.chainId == toChain?.chainId &&
        !customWallet?.length) ||
      (fromChain?.networkType == "evm" &&
        address === customWallet &&
        fromCoin?.address == toCoin?.address) ||
      (fromChain?.networkType == "sol" &&
        primaryWallet?.address === customWallet &&
        fromCoin?.address == toCoin?.address)
    ) {
      setCtaInfoText({
        text: "From and To asset can not be the same!",
        isGradient: false,
        color: "bw-bg-border-primary",
      });
    } else if (!routes?.data?.data?.quotes?.length) {
      setCtaInfoText({
        text: "No Routes Found",
        isGradient: false,
        color: "bw-bg-border-primary",
      });
    } else if (
      isConnected &&
      Number(fromChain?.chainId || "0") !== chain?.id &&
      fromChain?.networkType === "evm"
    ) {
      setCtaInfoText({
        text: `Switch Chain to ${fromChain.chain}`,
        isGradient: true,
        color: gradient ? gradient : styles.gradientbutton,
      });
    } else {
      handleError();
    }
  }, [
    fromCoin,
    toCoin,
    amount,
    fromChain,
    toChain,
    balances.data?.formatted,
    routesData,
    fetchBalance.data,
    fetchBalance.isFetching,
    fetchBalance.isLoading,
    isConnected,
    selectedCoinbalance,
    isBothWalletConnected,
    routes.isFetching,
    chain?.id,
    isAmountTyped,
    tronBal,
    balanceSol,
    customWallet,
  ]);

  function handleDisable() {
    return isBothWalletConnected
      ? routes.isFetching
        ? true
        : ctaInfoText.text.length
        ? ctaInfoText.text?.toLowerCase() ==
          `switch chain to ${fromChain.chain?.toLowerCase()}`
          ? false
          : true
        : !routes.data?.data?.quotes?.length
        ? true
        : false
      : false;
  }
  return (
    <button
      disabled={handleDisable()}
      style={
        ctaInfoText?.isGradient && isChainsLoaded && isCoinsLoaded
          ? {
              ...configuration?.gradientStyle,
              boxShadow:
                (configuration &&
                  configuration.customTheme &&
                  configuration.customTheme?.shadow?.boxShadow) ||
                "1px 1px 7px rgba(0, 0, 0, 0.15)",
            }
          : {
              boxShadow:
                (configuration &&
                  configuration.customTheme &&
                  configuration.customTheme?.shadow?.boxShadow) ||
                "1px 1px 7px rgba(0, 0, 0, 0.15)",
            }
      }
      className={`bw-w-full bw-rounded-sm bw-h-[52px] ${
        ctaInfoText?.isGradient ? "bw-p-[3px]" : "bw-p-[1px]"
      } bw-mt-3 bw-border-0 bw-relative bw-z-0  disabled:bw-opacity-70 ${
        isChainsLoaded && isCoinsLoaded && ctaInfoText.color
      } ${ctaInfoText.text.length > 29 ? "bw-text-xl" : "bw-text-2xl"} ${
        ctaInfoText?.isGradient
          ? "bw-font-bold bw-text-text-primary"
          : "bw-font-normal bw-text-text-secondary"
      }`}
      onClick={() => {
        if (!isBothWalletConnected) {
          handleShowWallet(true);
          if (
            ((fromChain?.networkType !== "evm" && isConnected) ||
              (fromChain?.networkType !== "sol" &&
                isFullyConnected &&
                primaryWallet !== null) ||
              (fromChain?.networkType !== "tron" &&
                connected &&
                tronAdrress)) &&
            !toDisconnect
          ) {
            setConnectWalletField("from");
          } else if (
            (isConnected && toChain?.networkType !== "evm") ||
            (isFullyConnected && toChain?.networkType !== "sol") ||
            (toChain?.networkType !== "tron" && connected && tronAdrress) ||
            toDisconnect
          )
            setConnectWalletField("to");
          else {
            setConnectWalletField("from");
          }
        } else handleSubmit();
      }}
    >
      <div className="bw-rounded-sm bw-bg-background-container bw-flex bw-items-center bw-justify-center b-w-full bw-h-full">
        <p className={`bw-relative bw-z-20`}>
          {isChainsLoaded && isCoinsLoaded ? (
            isBothWalletConnected ? (
              ctaInfoText.text.length ? (
                ctaInfoText.text
              ) : (
                "Start Transaction"
              )
            ) : (
              `Connect ${connectWalletName} Wallet`
            )
          ) : (
            <div className="bw-w-[30px] bw-flex bw-text-text-secondary bw-justify-center bw-items-center bw-h-[30px]">
              <CircularProgress size={22} thickness={5} color="inherit" />
            </div>
          )}
        </p>
      </div>
    </button>
  );
}
