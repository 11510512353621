import React, { useEffect, useState, useRef } from "react";
import SelectChain from "../SelectChain";
import useStore from "../../zustand/store";
import { useAccount } from "wagmi";
import { useQuery } from "react-query";
import controllers from "../../Actions/Controllers";
import LoadRoute from "./LoadRoute";
import AllRoutes from "./AllRoutes";
import ConfirmDetails from "../ConfirmDetails";
import SelectWallet from "../SelectWallet";
import {
  useDynamicContext,
  useUserWallets,
} from "@dynamic-labs/sdk-react-core";
import CoinCard from "./CoinCard";
import Header from "./Header";
import SwapAsset from "./SwapAsset";
import InputComp from "./InputComp";
import QuotesProgress from "./QuotesProgress";
import TxnButton from "./TxnButton";
import useRoutes from "../../hooks/useRoutes";
import useFromWallet from "../../hooks/useFromWallet";
import useWalletDetails from "../../hooks/useWalletDetails";
import useQuoteTimer from "../../hooks/useQuoteTimer";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import useSolBalance from "../../hooks/useSolBalance";
import useGetBalance from "../../hooks/useGetBalance";
export default function WidgetForm({ handleShowWallet, showWallet }) {
  const [showExchangeList, setShowExchangeList] = useState();
  const [showAllRoutes, setShowAllRoutes] = useState(false);
  const [confirmRoute, setConfirmRoute] = useState(false);
  const [stopRoute, setStopRoute] = useState(false);
  const [isAmountTyped, setIsAMountTyped] = useState(false);
  const [connectWalletField, setConnectWalletField] = useState("all");
  const [isTransactionSuccessful, setIsTransactionSuccessful] = useState(false);
  //wallet hooks
  const { primaryWallet, setPrimaryWallet } = useDynamicContext();
  const wallets = useUserWallets();
  const { address } = useAccount();
  const { address: tronAddress } = useWallet();
  const { getbal, isBalanceLoading } = useGetBalance();

  //zustand
  const configuration = useStore((state) => state.configuration);
  const persist = useStore((state) => state.persist);
  const setPersist = useStore((state) => state.setPersist);
  const route = useStore((state) => state.route);
  const fromChain = useStore((state) => state.fromChain);
  const toChain = useStore((state) => state.toChain);
  const fromCoin = useStore((state) => state.fromCoin);
  const toCoin = useStore((state) => state.toCoin);
  const setFromChain = useStore((state) => state.setFromChain);
  const setToChain = useStore((state) => state.setToChain);
  const setFromCoin = useStore((state) => state.setFromCoin);
  const setToCoin = useStore((state) => state.setToCoin);
  const amount = useStore((state) => state.amount);
  const setAmount = useStore((state) => state.setAmount);
  const customWallet = useStore((state) => state.customWallet);
  const setToDisconnect = useStore((state) => state.setToDisconnect);
  const { balanceSol, getBalanceSol } = useSolBalance(
    fromChain,
    isTransactionSuccessful,
    handleSuccess
  );
  console.log(getbal, balanceSol, "getbal");
  const isRoutesEnabled =
    amount.length &&
    fromChain.chain.length &&
    fromCoin.coin.length &&
    toChain.chain.length &&
    toCoin.coin.length &&
    !stopRoute &&
    isAmountTyped
      ? true
      : false;
  const routesDep = [
    "routes",
    address,
    fromChain,
    toChain,
    fromCoin,
    toCoin,
    amount,
    primaryWallet?.address,
    tronAddress,
    customWallet,
  ];

  //custom hooks src->hooks
  const { getWallet } = useWalletDetails({});
  const { routes, routesData, setRoutesData, errorMessage, setErrorMessage } =
    useRoutes({
      enabled: isRoutesEnabled,
      dependency: routesDep,
      payload: [
        getWallet(fromChain)?.address || "",
        fromChain,
        toChain,
        fromCoin,
        toCoin,
        amount,
        0,
        customWallet?.length > 30
          ? getWallet({})?.address || ""
          : getWallet(toChain)?.address || "",
      ],
    });
  const fromWalletAddress = getWallet(fromChain);
  // console.log(fromWalletAddress, "fromWalletAddress");
  const fetchBalance = useQuery(
    ["balance", fromWalletAddress?.address, fromChain],
    async () => {
      let res = await controllers.fetchBalance(
        fromWalletAddress.address,
        fromChain.chainId
      );
      return await res.json();
    },
    {
      enabled:
        fromWalletAddress?.address?.length && fromChain.chainId ? true : false,
      refetchOnWindowFocus: false,
    }
  );
  const fetchBalanceButton = useQuery(
    ["balancebutton", address],
    async () => {
      let res = await controllers.fetchBalance(address, "");
      return await res.json();
    },
    {
      enabled: address ? true : false,
      refetchOnWindowFocus: false,
    }
  );
  console.log(isTransactionSuccessful, "balanceSol2");
  const { quoteTimer } = useQuoteTimer({
    routes,
    routesData,
    confirmRoute,
    setConfirmRoute,
    isRoutesEnabled,
    fetchBalance,
    fetchBalanceButton,
  });
  useEffect(() => {
    if (!amount) {
      setRoutesData({});
    }
    let debounce;
    if (Number(amount > 0)) {
      debounce = setTimeout(() => {
        setIsAMountTyped(true);
      }, 500);
    }

    return () => {
      clearTimeout(debounce);
      setIsAMountTyped(false);
    };
  }, [amount]);
  useEffect(() => {
    if (routes.isFetching) {
      setRoutesData({});
    }
  }, [routes.isFetching]);
  function handleStopRoute(val) {
    setStopRoute(val);
  }

  function handleResetList() {
    setShowExchangeList();
  }
  function handleChain(data) {
    setAmount("");
    setErrorMessage({ error: "" });
    if (showExchangeList == "from") {
      setFromChain({ ...fromChain, ...data });
    } else if (showExchangeList == "to") {
      setToChain({ ...toChain, ...data });
    }
  }

  function handleCoin(data) {
    setErrorMessage({ error: "" });
    setAmount("");
    if (showExchangeList == "from") {
      setFromCoin({ ...fromCoin, ...data });
    } else if (showExchangeList == "to") {
      setToCoin({ ...toCoin, ...data });
    }
  }

  function handleShowAllRoutes() {
    setShowAllRoutes(!showAllRoutes);
  }

  function handleConfirmClose(val) {
    // if (!isEmpty(routesData)) {
    setConfirmRoute(typeof val !== "undefined" ? val : !confirmRoute);
    // }
  }
  function handleSuccess(val) {
    setIsTransactionSuccessful(val);
  }
  function handleRoutesData(data) {
    setRoutesData(data);
  }
  function resetBalance() {
    // fetchBalance.refetch();
    // fetchBalanceButton.refetch();
    setTimeout(() => {
      handleSuccess(true);
      fetchBalance.refetch();
      fetchBalanceButton.refetch();
    }, 1000);
  }
  function resetAmount() {
    setAmount("");
  }
  function fetchTokens(item, setCoin, config) {
    controllers
      .fetchTokens(item.chainId, config)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        res.data?.forEach((item) => {
          setCoin({
            ...fromCoin,
            coin: item.symbol,
            ...item,
            availBal: "",
          });
        });
      });
  }
  const fetchChains = useQuery(
    "chainsfront",
    async function () {
      let res = await controllers.fetchChain();
      return await res.json();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (datas) => {
        datas?.data?.forEach((item) => {
          if (
            !fromChain?.chain.length &&
            item.chainId ===
              (configuration?.defaultChains?.from?.chainId || "10")
          ) {
            setFromChain({ ...fromChain, chain: item.name, ...item });
            fetchTokens(
              item,
              setFromCoin,
              configuration?.defaultTokens?.from?.tokenAddress ||
                "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
            );
          }
          if (
            !toChain?.chain.length &&
            item.chainId ===
              (configuration?.defaultChains?.to?.chainId || "sol")
          ) {
            setToChain({ ...toChain, chain: item.name, ...item });
            fetchTokens(
              item,
              setToCoin,
              configuration?.defaultTokens?.to?.tokenAddress ||
                "So11111111111111111111111111111111111111112"
            );
          }
        });
      },
    }
  );
  function handleSwap() {
    setAmount("");
    setFromChain(toChain);
    setFromCoin(toCoin);
    setToChain(fromChain);
    setToCoin(fromCoin);
    // setToDisconnect(false);
  }
  useEffect(() => {
    // console.log("releoad");
    if (persist.isHistory && route === "") {
      setPersist({});
      handleStopRoute(false);
    }
  }, [persist]);
  return (
    <div className="bw-relative">
      {showWallet ? (
        <SelectWallet
          connectWalletField={connectWalletField}
          handleShowWallet={handleShowWallet}
        />
      ) : !confirmRoute && !Boolean(persist?.routeId?.length) ? (
        !showAllRoutes ? (
          !showExchangeList ? (
            <>
              <Header />
              <div className="bw-flex bw-relative  bw-items-center bw-gap-x-2 bw-justify-between">
                <SwapAsset handleSwap={handleSwap} />
                <CoinCard
                  chainData={fromChain}
                  show={"from"}
                  handleShowWallet={handleShowWallet}
                  setShowExchangeList={setShowExchangeList}
                  setConnectWalletField={setConnectWalletField}
                  coinData={fromCoin}
                />
                <CoinCard
                  chainData={toChain}
                  show={"to"}
                  handleShowWallet={handleShowWallet}
                  setShowExchangeList={setShowExchangeList}
                  setConnectWalletField={setConnectWalletField}
                  coinData={toCoin}
                />
              </div>
              <InputComp
                amount={amount}
                setAmount={setAmount}
                fetchBalance={fetchBalance}
                getCoinBalance={getbal}
                isBalanceLoading={isBalanceLoading}
              />

              <div>
                <LoadRoute
                  routes={routes}
                  handleShowAllRoutes={handleShowAllRoutes}
                  routesData={routesData}
                  amount={amount}
                  isRoutesEnabled={isAmountTyped}
                  price={
                    Number(
                      routesData?.minOutputAmount ||
                        routesData?.outputAmountDisplay
                    ) * toCoin?.lastPrice || 0
                  }
                />
              </div>
              <p className="bw-text-xs bw-font-normal bw-py-1 bw-text-text-redtext">
                {errorMessage?.error}
              </p>
              <QuotesProgress
                routes={routes}
                routesData={routesData}
                confirmRoute={confirmRoute}
                setConfirmRoute={setConfirmRoute}
                isRoutesEnabled={isRoutesEnabled}
                quoteTimer={quoteTimer}
              />
              <TxnButton
                routesData={routesData}
                routes={routes}
                fetchBalancePrev={fetchBalance}
                amount={amount}
                isAmountTyped={isAmountTyped}
                handleShowWallet={handleShowWallet}
                setConnectWalletField={setConnectWalletField}
                handleConfirmClose={handleConfirmClose}
                fetchBalance={fetchBalanceButton}
                balanceSol={balanceSol}
                getCoinBalance={getbal}
              />
            </>
          ) : (
            <SelectChain
              chainData={showExchangeList == "from" ? fromChain : toChain}
              coinData={showExchangeList == "from" ? fromCoin : toCoin}
              setChainData={handleChain}
              setCoinData={handleCoin}
              handleReset={handleResetList}
              showExchangeList={showExchangeList}
              fetchBalance={fetchBalance}
              fetchChains={fetchChains}
            />
          )
        ) : (
          <AllRoutes
            routes={routes}
            handleShowAllRoutes={handleShowAllRoutes}
            handleRoutesData={handleRoutesData}
            convertVal={toCoin?.lastPrice || 0}
            amount={amount}
          />
        )
      ) : (
        <ConfirmDetails
          handleConfirmClose={handleConfirmClose}
          amount={amount}
          routesData={routesData}
          resetAmount={resetAmount}
          routes={routes}
          handleStopRoute={handleStopRoute}
          resetBalance={resetBalance}
        />
      )}
    </div>
  );
}
