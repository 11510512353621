import useStore from "../../zustand/store";
import CloseButton from "../Svg/closeButton";
import ConnectWalletProgress from "../ConnectWalletProgress";
import BackButton from "../Svg/backButton";
import { useConnect } from "wagmi";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import {
  useWalletItemActions,
  useDynamicContext,
} from "@dynamic-labs/sdk-react-core";
import React, { useEffect, useState } from "react";
import { useConnect as useCosmosConnect, WalletType } from "graz";
import useRegisterError from "../../hooks/useRegisterError";
import validError from "../../utils/validError";
export default function ConnectorLoader({
  selectedWallet,
  selectedConnector,
  handleConnect,
  isSolConnecting,
  isCosmosonnecting,
  handleOtherWalletConnecting,
  data,
  isTronConnecting,
  isSuccess,
  evmError,
}) {
  const configuration = useStore((state) => state.configuration);
  const gradient = useStore((state) => state.gradient);
  const { select, connect, wallet } = useWallet();
  const { openWallet } = useWalletItemActions();
  const { use } = useDynamicContext();
  const { error } = useConnect();
  const { registerError } = useRegisterError();
  const { connect: cosmosConnect } = useCosmosConnect({
    onError: (err) => {
      console.log(err, err.status, "errorcos");
      setErrorMessage(err.message);
    },
  });
  const [errorMessage, setErrorMessage] = useState("");
  let errorText = evmError?.length ? evmError : errorMessage;
  async function handleSolConnect() {
    setErrorMessage("");
    try {
      openWallet(selectedWallet);
    } catch (e) {
      setErrorMessage(e.message);
      console.log(e, "solerror");
    }
  }
  async function handleTronConnect() {
    setErrorMessage("");
    try {
      if (wallet) await connect(selectedWallet);
      else await select(selectedWallet);
    } catch (er) {
      setErrorMessage(er.message);
      console.log(er.message, "error");
    }
  }

  useEffect(() => {
    let isValid = validError(errorText || "");
    if (errorText.length && isValid) {
      registerError({
        mode: "wallet connection",
        error: errorText,
      });
    }
  }, [errorText]);
  async function handleCosmosConnect() {
    setErrorMessage("");
    try {
      let res = cosmosConnect({
        chainId: "cosmoshub-4",
        walletType: WalletType.KEPLR,
      });
      console.log(res, "Res");
    } catch (err) {
      setErrorMessage(err.message);
      console.log(err.message, "errorcos");
    }
  }
  return (
    <div className="bw-flex bw-w-full bw-flex-col bw-justify-center bw-items-center">
      <div className="bw-flex bw-w-full bw-relative bw-justify-center bw-mb-2">
        <button
          onClick={() => {
            handleOtherWalletConnecting();
          }}
          className="bw-absolute bw-left-0 bw-top-[25%]"
        >
          <BackButton fill={"var(--tw-text-secondary)"} />
        </button>
        <div className="bw-text-lg bw-font-normal bw-text-text-secondary">
          Connect Wallet
        </div>
        <button
          onClick={() => {
            handleOtherWalletConnecting();
          }}
          className="bw-absolute bw-right-0 bw-top-[25%]"
        >
          <CloseButton fill={"var(--tw-text-secondary)"} />
        </button>
      </div>
      <ConnectWalletProgress
        selectedWallet={selectedWallet}
        isSuccess={isSuccess}
        data={data}
        isSolConnecting={isSolConnecting}
        isCosmosonnecting={isCosmosonnecting}
        isTronConnecting={isTronConnecting}
      />
      <p className="bw-text-lg bw-mt-2 bw-font-medium bw-text-text-primary">
        Continue in {selectedWallet || ""}
      </p>
      <p className="bw-text-lg bw-font-normal bw-mt-2 bw-text-text-secondary">
        Accept connection request in the wallet
      </p>
      <p className="bw-text-text-redtext bw-text-xs bw-relative bw-top-1">
        {errorText}
      </p>
      <div
        style={{
          ...configuration.gradientStyle,
          boxShadow:
            (configuration &&
              configuration.customTheme &&
              configuration.customTheme?.shadow?.boxShadow) ||
            "1px 1px 7px rgba(0, 0, 0, 0.15)",
        }}
        className={`bw-w-full bw-mt-3 bw-rounded-sm bw-h-full bw-p-[3px] ${
          gradient
            ? gradient
            : `${
                !configuration?.gradientStyle?.background
                  ? "bw-bg-gradient-to-r  bw-from-[#2CFFE4] bw-to-[#A45EFF]"
                  : ""
              }`
        }`}
      >
        <button
          onClick={() => {
            errorText = "";
            if (isSolConnecting) {
              handleSolConnect();
            } else if (isTronConnecting) {
              handleTronConnect();
            } else if (isCosmosonnecting) {
              handleCosmosConnect();
            } else {
              setErrorMessage("");
              handleConnect(selectedConnector);
            }
          }}
          className="bw-w-full bw-flex bw-bg-background-container bw-justify-center bw-items-center bw-h-[50px]  bw-rounded-sm  "
        >
          <span
            style={{ ...configuration.gradientStyle, backgroundClip: "text" }}
            className={`  bw-text-text-primary  bw-text-2xl bw-font-bold `}
          >
            Try Again
          </span>
        </button>
      </div>
    </div>
  );
}
